<template>
  <v-card rounded flat outlined max-width="300px" @click="$refs.input.$refs.input.click()">
    <v-img v-if="imageURL" :src="imageURL" width="300px">
      <v-layout justify-center align-center fill-height>
        <v-icon large>
          mdi-image-edit
        </v-icon>
      </v-layout>
    </v-img>

    <v-sheet v-else width="300px" height="200px" color="#ffd600">
      <v-layout justify-center align-center fill-height>
        <v-icon dark large>
          mdi-image-edit
        </v-icon>
      </v-layout>
    </v-sheet>

    <v-file-input ref="input" v-model="image" class="d-none" hide-input />
  </v-card>
</template>

<script>
import { defineComponent, reactive, watch, toRefs, onBeforeMount } from '@vue/composition-api'

export default defineComponent({
  props: { value: [String, Object] },

  setup(props, { emit }) {
    const state = reactive({
      image: null,
      imageURL: null,
    })

    onBeforeMount(() => {
      if (typeof props.value === 'string') state.imageURL = props.value
      else state.image = props.value
    })

    watch(
      () => props.value,
      () => {
        if (typeof props.value === 'string') state.imageURL = props.value
        else state.image = props.value
      },
    )

    watch(
      () => state.image,
      () => {
        state.imageURL = URL.createObjectURL(state.image)

        emit('input', state.image)
      },
    )

    return {
      ...toRefs(state),
    }
  },
})
</script>
