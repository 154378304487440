<template>
  <v-container fluid>
    <v-card>
      <v-list>
        <v-card-title>공지사항</v-card-title>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>제목</v-list-item-title>
            <v-text-field v-model="input.title" outlined dense hide-details />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>대문 이미지</v-list-item-title>
            <v-list-item-subtitle>
              공지사항 대문으로 등록될 이미지를 추가해주세요
            </v-list-item-subtitle>
            <ImageInput v-model="input.image" />
          </v-list-item-content>
        </v-list-item>
        <MarkdownEditor v-model="input.content" />
        <v-card-actions>
          <v-spacer />

          <v-btn large outlined to="/notice" exact>취소</v-btn>
          <v-btn large color="#7f00ff" dark @click="createNotice">등록</v-btn>
        </v-card-actions>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, reactive, toRefs } from '@vue/composition-api'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import ImageInput from '@/components/input/Image.vue'

export default defineComponent({
  name: 'NoticeCreate',

  components: {
    MarkdownEditor,
    ImageInput,
  },

  setup(props, { root }) {
    const state = reactive({
      isShow: false,
      item: null,
      input: {
        title: null,
        content: null,
        fcmSend: false,
        image: null,
      },
    })

    const createNotice = async () => {
      try {
        if (state.item && state.item.noticeId) {
          await root.$store.dispatch('notice/updateNotice', {
            title: state.input.title,
            content: state.input.content,
            image: state.input.image,
            noticeId: Number(state.item.noticeId),
          })

          alert('수정완료!')

          state.isShow = false
          state.input.title = null
          state.input.content = null
        } else {
          await root.$store.dispatch('notice/createNotice', state.input)

          alert('생성완료!')

          if (!state.item) root.$router.push({ name: '공지사항' })

          state.isShow = false
          state.input = {
            title: null,
            content: null,
            image: null,
            fcmSend: false,
          }
        }
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    return {
      ...toRefs(state),
      createNotice,
    }
  },
})
</script>
